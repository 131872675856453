import { UserMetadataPublic } from "@/services/types"

// Define all the available roles in the application
export enum Role {
  SwiftAdmin = "swift:admin",
  PartnerAdmin = "partner:admin",
  PartnerViewer = "partner:viewer",
  Guest = "guest",
}

// Function to convert a role to a human-readable string
export const humanReadableRole = (role: Role): string => {
  switch (role) {
    case Role.SwiftAdmin:
    case Role.PartnerAdmin:
      return "Admin"
    case Role.PartnerViewer:
      return "Viewer"
    case Role.Guest:
      return "Guest"
    default:
      return new Error(`Invalid Role: ${role}`).message
  }
}

export const publicRoles = [
  {
    value: Role.PartnerAdmin,
    label: humanReadableRole(Role.PartnerAdmin),
  },
  {
    value: Role.PartnerViewer,
    label: humanReadableRole(Role.PartnerViewer),
  },
  {
    value: Role.Guest,
    label: humanReadableRole(Role.Guest),
  },
]

// Define an array of permissions
const permissionsArray = [
  "manageApiKeys",
  "managePartners",
  "manageUsers",
  "delete",
  "read",
  "write",
  "rtkMap",
  "administration",
  "profile",
] as const

// Create a union type for all permissions
export type Permission = (typeof permissionsArray)[number]

// Define the permissions for each role
const rolePermissions: Record<Role, Permission[]> & {
  [key: string]: string[]
} = {
  [Role.SwiftAdmin]: [
    "delete",
    "manageApiKeys",
    "managePartners",
    "manageUsers",
    "read",
    "rtkMap",
    "write",
    "administration",
  ],
  [Role.PartnerAdmin]: [
    "delete",
    "manageApiKeys",
    "manageUsers",
    "read",
    "rtkMap",
    "write",
    "administration",
    "profile",
  ],
  [Role.PartnerViewer]: ["read", "rtkMap", "profile"],
  [Role.Guest]: ["rtkMap", "profile"],
}

// Function to check if a user has the required permission
export const hasPermission = (
  metadata: UserMetadataPublic,
  permission: Permission,
): boolean => {
  // Check that metadata is defined before checking the role
  if (!!metadata && !metadata.role) {
    return false
  }

  // Check if the user has a valid role
  if (!rolePermissions[metadata.role]) {
    throw new Error(`Invalid role: ${metadata?.role}`)
  }

  return rolePermissions[metadata.role].includes(permission)
}
