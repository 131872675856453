import { Field, Input, Label, Radio, RadioGroup } from "@headlessui/react"
import { useForm } from "@tanstack/react-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createFileRoute, useNavigate, useSearch } from "@tanstack/react-router"
import React from "react"

import AdminTabs from "@/components/AdminTabs"
import Button from "@/components/Button"
import FieldInfo from "@/components/FieldInfo"
import FormErrors from "@/components/FormErrors"
import PartnerSelect from "@/components/PartnerSelect"
import { useAuth } from "@/contexts/auth"
import { createUser } from "@/services/portalApi"
import { hasPermission, publicRoles } from "@/utils/rbac"

export const Route = createFileRoute("/_auth/administration/users/create")({
  component: () => <CreateUserPage />,
  staticData: {
    title: "Administration: Invite User",
  },
})

const CreateUserPage = () => {
  const { partnerId } = useSearch({ strict: false })
  const { metadataPublic } = useAuth()
  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const invitedUser = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      navigate({ to: "/administration/users", search: (prev) => prev })
      queryClient.invalidateQueries({
        queryKey: ["users", "list", { partnerId }],
      })
    },
    onError: (err) => {
      form.setErrorMap({ onSubmit: err.message })
    },
  })

  const form = useForm({
    defaultValues: {
      name: {
        first: "",
        last: "",
      },
      email: "",
      org_id: partnerId ?? metadataPublic.org_id,
      role: "partner:viewer",
    },
    validators: {
      onSubmit: () => {
        if (!hasPermission(metadataPublic, "manageUsers")) {
          return "You do not have permission to invite users"
        }

        return null
      },
    },
    onSubmit: ({ value }) => invitedUser.mutate(value),
  })

  return (
    <>
      <AdminTabs />
      <form
        className="max-w-md bg-white p-4 rounded shadow"
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        {metadataPublic.role === "swift:admin" && (
          <form.Field
            name="org_id"
            children={(field) => {
              return (
                <>
                  <Field className="mb-5">
                    <Label
                      htmlFor="auth-group"
                      className="block text-sm text-gray-500 mb-1.5"
                    >
                      Partner Name
                    </Label>
                    <PartnerSelect
                      defaultOption="Select a Partner"
                      defaultDisabled
                      id={field.name}
                      name={field.name}
                      onChange={(e) => {
                        navigate({
                          to: "/administration/users/create",
                          search: (prev: any) => ({
                            ...prev,
                            partnerId:
                              e.target.value === ""
                                ? undefined
                                : e.target.value,
                          }),
                        })

                        return field.handleChange(e.target.value)
                      }}
                      value={field.state.value}
                    />
                  </Field>
                  <FieldInfo field={field} />
                </>
              )
            }}
          />
        )}
        <form.Field
          name="name.first"
          children={(field) => {
            return (
              <>
                <Field className="mb-5">
                  <Label
                    htmlFor={field.name}
                    className="block text-sm text-gray-500 mb-2"
                  >
                    First Name
                  </Label>
                  <Input
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeholder="First Name"
                    value={field.state.value}
                  />
                  <FieldInfo field={field} />
                </Field>
              </>
            )
          }}
        />

        <form.Field
          name="name.last"
          children={(field) => {
            return (
              <>
                <Field className="mb-5">
                  <Label
                    htmlFor={field.name}
                    className="block text-sm text-gray-500 mb-2"
                  >
                    Last Name
                  </Label>
                  <Input
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeholder="Last Name"
                    value={field.state.value}
                  />
                  <FieldInfo field={field} />
                </Field>
              </>
            )
          }}
        />

        <form.Field
          name="email"
          validators={{
            onChange: ({ value }) => (!value ? "Email is required" : undefined),
            onChangeAsyncDebounceMs: 500,
          }}
          children={(field) => {
            // Avoid hasty abstractions. Render props are great!
            return (
              <>
                <Field className="mb-5">
                  <Label
                    htmlFor={field.name}
                    className="block text-sm text-gray-500 mb-2"
                  >
                    Email
                  </Label>
                  <Input
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id={field.name}
                    name={field.name}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    placeholder="email@address.com"
                    type="email"
                    value={field.state.value}
                  />
                  <FieldInfo field={field} />
                </Field>
              </>
            )
          }}
        />

        <form.Field
          name="role"
          children={(field) => {
            return (
              <>
                <Field className="mb-5">
                  <Label
                    htmlFor={field.name}
                    className="block text-sm text-gray-500 mb-2"
                  >
                    Role / Permissions
                  </Label>
                  <RadioGroup
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    aria-label="Role"
                    onChange={(value) => field.handleChange(value)}
                  >
                    {publicRoles.map((role) => (
                      <Field
                        key={role.label}
                        className="flex items-center gap-2"
                      >
                        <Radio
                          value={role.value}
                          className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-500"
                        >
                          <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                        </Radio>
                        <Label>{role.label}</Label>
                      </Field>
                    ))}
                  </RadioGroup>
                </Field>
              </>
            )
          }}
        />

        <form.Subscribe
          selector={(state) => [
            state.errors,
            state.canSubmit,
            state.isSubmitting,
          ]}
          children={([errors, canSubmit, isSubmitting]) => (
            <>
              <FormErrors errors={errors} />
              <div className="flex justify-end gap-4">
                <Button type="reset" color="reset" onClick={() => form.reset()}>
                  Reset
                </Button>

                <Button type="submit" disabled={!canSubmit} color="blue">
                  {isSubmitting ? "Submitting" : "Submit"}
                </Button>
              </div>
            </>
          )}
        />
      </form>
    </>
  )
}
